import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { ArticleWithCreators } from 'apiFolder/directus-main';

import Seo from 'components/seo';
import { Content } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';
import ArticlesGrid from 'components/ArticlesGrid';

interface IArticleWithCreators {
	pageContext: {
		dataPainArticles: ArticleWithCreators[];
	};
}

const Pain: React.FC<IArticleWithCreators> = ({ pageContext: { dataPainArticles } }) => {
	const [isMenuOpen, toggleMenu] = useState(false);

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo
				title='Pain Articles: Pain Content Written by Experts'
				description='A comprehensive library of pain content written and reviewed by pain experts. Get the most up-to-date information on how to treat your pain today.'
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<TitleBlock>
						<Wrap>
							<Title>Pain Articles</Title>
							<LinksBlock>
								<Link to={'/editorial-team'}>Editorial Team</Link>
								<Link to={'/editorial-policy'}>Editorial Policy</Link>
								<Link to={'/content-disclaimer'}>Content Disclaimer</Link>
								<Link to={'/advertising-policy'}>{`Ad & Sponsorship Policy`}</Link>
							</LinksBlock>
						</Wrap>
					</TitleBlock>
					<Content isOpen={isMenuOpen}>
						<ArticlesGrid articles={dataPainArticles} />
					</Content>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};
const TitleBlock = styled.div`
	display: relative;
	left: 0;
	width: 100%;
	background-color: #f7f8fa;
	padding: 80px 0;
	border-bottom: 1px solid #bdc5cd;

	@media (max-width: 767px) {
		padding: 40px 0;
	}
`;
const Wrap = styled.div`
	max-width: 1140px;
	width: 100%;
	margin: 0 auto;
	padding: 0 15px;
`;
const Title = styled.h1`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	font-weight: 600;
	line-height: 1.3;
	color: #152231;
	font-size: 30px;

	@media (max-width: 1024px) {
		font-size: 24px;
	}
`;

const LinksBlock = styled.ul`
	display: flex;
	flex-wrap: wrap;
	margin-top: 50px;
	& a {
		white-space: nowrap;
		margin: 0 40px 20px 0;
		color: ${({ theme }) => theme.palette.purp};
		transition: all ease 0.3s;

		@media (min-width: 1367px) {
			&:hover {
				text-decoration: none;
				color: ${({ theme }) => theme.palette.grey_text};
			}
		}
		@media (max-width: 767px) {
			flex: 0 0 calc(49% - 25px);
			margin: 0 10px 20px 0;

			&:nth-child(1) {
				order: 1;
			}
			&:nth-child(2) {
				order: 3;
			}
			&:nth-child(3) {
				order: 2;
			}
			&:nth-child(4) {
				order: 4;
			}
		}
	}
`;

export default Pain;
